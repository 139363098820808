<template>
  <div class="files">
    <br>
    <ATitle text="资料下载"/>
    <br>
    <div class="files-wrap center">
      <a v-for="(item, index) in fileList" :key="index" :href="'https://itie-static.oss-cn-hangzhou.aliyuncs.com/文件/' + item.name + '?t=' + new Date().valueOf()" target="_block"><img src="@/assets/attachments.png"> • {{item.name}}<span class="fr">{{item.time}}</span></a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fileList: [
        {
          time: '2023-10-18',
          name: '2023第五届博览会招展手册【中英版】.pdf'
        },
        {
          time: '2022-02-07',
          name: '2021第四届博览会招展手册【中英版】.pdf'
        },
        {
          time: '2020-09-17',
          name: '2020第三届博览会招展手册【中英版】.pdf'
        },
        {
          time: '2020-09-04',
          name: '2020第二届综合交通创新创业大赛报名表.docx'
        },
        {
          time: '2020-08-26',
          name: '2020短视频征集参赛人员信息登记表.docx'
        },
        {
          time: '2020-08-26',
          name: '2020漫画征集参赛人员信息登记表.docx'
        },
        {
          time: '2019-11-18',
          name: '第二届博览会参展商手册.pdf'
        },
        {
          time: '2019-08-07',
          name: '第二届博览会招展手册.pdf'
        },
        {
          time: '2019-08-07',
          name: '第二届浙江国际智慧交通产业博览会未来交通大会招展公告.pdf'
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.files {
  min-height: 550px;
  padding-bottom: 100px;
  background: url('../assets/views_bg.png') no-repeat scroll bottom / 100%;
  .files-wrap {
    border-top: 4px solid #4A90E2;
    box-shadow: 0 0 4px #CCC;
    padding: 2px 20px;
    padding-bottom: 100px;
    background: #FFF;
    a {
      font-size: 18px;
      color: #666666;
      display: block;
      height: 60px;
      line-height: 60px;
      border-bottom: 1px dashed #555;
      transition: all ease-in 0.2s;
      img {
        height: 24px;
        width: auto;
        position: relative;
        top: 5px;
      }
    }
    .file:hover {
      color: #4A90E2;
    }
  }
}
</style>